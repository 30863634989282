import { addBackToTop } from 'vanilla-back-to-top'

// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import $ from 'jquery';

// $(document).ready(() => {
//   console.log('Hello word!');
// });

// Your scripts here

addBackToTop({
  diameter: 42,
  backgroundColor: 'rgb(253, 234, 17)',
  textColor: 'rgb(33, 37, 41)'
});
